
.chapter3Mobile {
    position: relative;
    width: 100%;
    padding: 0;
    background-color: #c891ff;
    min-height: 80vh;
}

.chapter3MobileContent{
    padding-left: 5vw;
}

.chapterMobile3H1 {
    text-transform: uppercase;
    font-family: BerlinSans;
    letter-spacing: 0.2rem;
    font-size: calc(max(2.8vw,28px));
    color: #ffffff;
    opacity: 1;
    width: 100vh;
    padding-left: 5vw;
    padding-right: 5vw;
    z-index: 1;
}

.astronautMobile{
    width: 100%;
}