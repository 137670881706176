/* navbar.css */

.navbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
}
  
  
  .navbarLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #2B2822;
    border-radius: 8px;
    width: 333px;
    flex-shrink: 1;
    padding: 8px;
    position: relative;

  }
  
  .navbarLinks a {
    flex-basis: 33.3333%; /* Ensure each link takes up a third of the container's width */
    margin-right: 20px;
    margin-left: 20px;
    color: black;
    text-decoration: none;
    font-size: 16px;
    width: 50px;
  }
  .navbarLinks .left-link {
    text-align: left; /* Left align text within the first link */
  }
  
  .navbarLinks .center-link {
    text-align: center; /* Center align text within the second link */
  }
  
  .navbarLinks .right-link {
    text-align: right; /* Right align text within the third link */
  }
  .navbarLinks a:hover {
    color: #086A24 !important;
    transition: background-image 0.5s ease; /* Smooth transition for the hover effect */
  }

  .navbarLogo {
    display: flex;
    text-align: left;
  }
  
  .navbarSocials {
    display: flex;
    align-items: center;

    gap: 30px;
  }
  
  .navbarSocials img {
    margin-left: 10px;
    height: 30px;
  }
  
  .iconTwitter {
    height: min(calc(1.9vw), 19px);
    width:min(calc(2vw), 20px);
    
    background-image: url(../img/icons/XIcon.png);
    background-size: cover;  /* Cover the entire area of the container */
    background-position: center; /* Center the background image */
    transition: background-image 0.3s ease; /* Smooth transition for the hover effect */
  }
  .iconTwitter:hover {
    background-image: url(../img/icons/Icon_X_green.png);
  }
  .iconGithub {
    height: min(calc(2vw), 20px);
    width:min(calc(2vw), 20px);
    background-image: url(../img/icons/GithubIcon.png);
    background-size: cover;  /* Cover the entire area of the container */
    background-position: center; /* Center the background image */
    transition: background-image 0.3s ease; /* Smooth transition for the hover effect */
  }
  .iconGithub:hover {
    background-image: url(../img/icons/Icon_Github-green.png);
  }
  .iconDiscord {
    height: min(calc(1.542vw), 15.42px);
    width:min(calc(2.0vw), 20px);
    background-image: url(../img/icons/DiscordIcon.png);
    background-size: cover;  /* Cover the entire area of the container */
    background-position: center; /* Center the background image */
    transition: background-image 0.3s ease; /* Smooth transition for the hover effect */
  }
  .iconDiscord:hover {
    background-image: url(../img/icons/Icon_Discord-green.png);
  }
  .iconTelegram {
    height: min(calc(2.2vw), 20px);
    width:min(calc(2.0vw), 20px);
    background-image: url(../img/icons/TelegramIcon.png);
    background-size: cover;  /* Cover the entire area of the container */
    background-position: center; /* Center the background image */
    transition: background-image 0.3s ease; /* Smooth transition for the hover effect */
  }
  .iconTelegram:hover {
    background-image: url(../img/icons/Icon_Telegram-green.png);
  }