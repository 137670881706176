.stepperMobileHeading{
    font-family: OpenSansBold;
    letter-spacing: 0rem;
    font-size: calc(max(2vw,20px));
    color: #ffffff;
    padding-top: 20px;
    padding-left: 2vw;
    opacity: 1;
    width: 100vw;
    margin: 0;
  }
  
  .stepperMobileText{
    font-family: OpenSansLight;
    letter-spacing: 0rem;
    font-size: calc(max(1.4vw,14px));
    color: #ffffff;
    padding-top: 2vh;
    padding-left: 2vw;
    padding-right: 10vw;
    opacity: 1;
    width: 100vw;
    margin: 0;
  }