/* section1.css */

.chapter1Background {
  background: #EFEFEF;
  min-height: calc(max(100vh,850px));
  display: flex;
  width: 100%;
  justify-content: center; /* Center content horizontally */
}

.chapter1Container {
  position: absolute; /* Ensure positioning relative to parent container */
  top: 0;
  max-width: 1320px; /* Maximum width of the div */
  width: 100%; /* Full width when the viewport is smaller */
  margin: 0 auto; /* Center the div horizontally */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  padding: 0 16px; /* Optional: Add some horizontal padding */

}

.main {
  min-height: calc(max(80vh,700px));
  background: #A3D261;
  border-radius: 20px;
  padding: 20px; /* Adjust padding as needed */
  box-sizing: border-box; /* Ensure padding is included in total width */
  display: flex;
  justify-content: center; /* Center child horizontally */
}

.chapter1Logo {
  position: absolute; /* Ensure positioning relative to parent container */
  bottom: 0 !important; /* Align to the bottom of the container */
  margin-bottom: 0px; /* Optional: Adjust margin from the bottom */
  left: 50%;
  transform: translateX(-50%);
  width: calc(min(100vw,1000px));
}


.chapter1Headline {
  font-family: 'PT Sans';
  font-size: 24px;
  font-weight: bold;
  color: #086A24;
  padding-top: 5vh;
  text-align: center;
  margin-bottom: 40px;
}

.claimText {
  font-family: 'Helvetica', sans-serif;
  font-size: calc(min(6vw,48px));
  width: calc(min(80vw,1000px));
  text-align: center;
  line-height: 1.2; /* 1.2 times the font size */
  margin-bottom: 40px;
}

.chapter1Text {
  font-size: 2vw;
  color: black;
  padding-right: 10vw;
}
.chapter1ButtonLine {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  gap: 10px; /* Adjust the spacing between buttons */
}

.chapter1ButtonGreen {
  color: white;
  background-color: #086A24;
  border-radius: 30px;
  font-size: 18px;
  font-family: 'PT Sans';
  padding-left: 38px;
  padding-right: 38px;
  padding-top: 12px;
  padding-bottom: 12px;

}
.chapter1ButtonGreenUnfilled {
  color: #2B2822;
  border-style: solid;
  border-width: 1px;
  border-color: #2B2822;
  border-radius: 30px;
  font-size: 18px;
  font-family: 'PT Sans';
  font-weight: bold;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}