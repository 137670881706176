.chapter4Mobile {
    position: relative;
    background-color: #d0ffa3;
    width: 100%;
    min-height:50vh;
    margin: 0vw;
    padding-left: 4vw;
    padding-right: 4vw;
    z-index: 0;
  }
  
.chapter4MobileLayer{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: #d0ffa3;
    opacity: 0.7;
    z-index: -1;
}  
  
  .chapter4MobileH1{
    text-align: center;
    letter-spacing: 0.05rem;
    font-size: max(calc(2.2vw), 22px);
    color: #000000;
    padding-top: 18vh;
    padding-right: 5vw;
    opacity: 1;
    width: 100vw;
    margin: 0;
    z-index: 1;
  }
  .chapter4MobileContent{
    letter-spacing: 0.05rem;
    font-size: max(calc(2.0vw), 20px);
    padding-top: 0vh;
    padding-right: 10vw;
    width: 100vw;
    margin: 0;
    z-index: 1 !important;
  }
  
  .chapter4MobileTwinLogo{
    width: 50vw;
  }
  .chapter4MobileBeraLogo{
    width: 60vw;
  }

  .chapter4MobileBamboo{
    position: absolute;
    top: 25vh;
    left: 0px;
    width: calc(min(200px,55vw));
    z-index: -2;
  }
  .chapter4MobileHoneycomb{
    position: absolute;
    bottom: 35%;
    left: 6vw;
    width: calc(min(350px,50vw));
    z-index: -1;
  }
  .pandaDonutMobile{
    position: absolute;
    top: 10px;
    right: 10px !important;
    width: 40vw;
    padding-left: 5vw;
    z-index: 1;
  }