.chapter5Mobile {
  position: relative;
  width: 100%;
  background-image: url(../img/graphics/TWIN_Chapter5_Background.png);
  background-size: contain;
  background-repeat: repeat-y;
  z-index: 0;
}

.chapterMobile5H1 {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  font-family:  BerlinSans;
  letter-spacing: 0.0rem;
  font-size: max(calc(3.5vw), 35px);
  color: #000000;
  padding-top: 20vh;
  opacity: 1;
  width: 100%;
  padding-left: 5vw; 
  padding-right: 5vw;
  padding-bottom: 10px;
  z-index: 2;
}

.chapter5MobileLayer{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #fff4e2;
  opacity: 0.2;
  z-index: -1;
}  

.chapter5MobileSubheadline {
  position: relative;
  text-align: center;
  font-family:  BerlinSans;
  letter-spacing: 0.0rem;
  font-size: max(calc(2.5vw), 25px);
  color: #000000;
  opacity: 1;
  width: 100%;
  padding-left: 5vw; 
  padding-right: 5vw;
  padding-bottom: 0px;
}

.chapter5MobileSubheader {
  font-family: OpenSansRegular;
  font-weight: bold;
  font-size: max(calc(2vw), 20px);
  color: #000000 !important;
  padding-top: 0vh;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
}
.chapter5MobileSubtext {
  font-size: max(calc(1.5vw), 15px);
  padding-top: 0vh;
  opacity: 1;
  padding-left: 10px;
  padding-right: 10px;
}

.chapter5MobilePanda{
  position: absolute;
  
  width: calc(min(450px,35vw));
  z-index: 4;
  animation: moveAroundMobile 10s infinite linear;
}

.chapter5MobileHoney{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
@keyframes moveAroundMobile {
  0% {
    top: 2vh;
    left: 36vw;
  }
  25% {
    top: 4vh;
    left: 38vw;
  }
  50% {
    top: 6vh;
    left: 38vw;
  }
  75% {
    top: 4vh;
    left: 36vw;
  }
  100% {
    top: 2vh;
    left: 36vw;
  }
}


.chapter5MobileText {
  font-family: OpenSansRegular;
  font-size: max(calc(1.3vw), 14px);
  color: #000000 !important;
  opacity: 1;
  padding-top: 30px;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 3vw;
  z-index: 2;
}
.chapter5MobileTextBox {
  width: 100% !important;
  padding-bottom: 40px;
  padding-left: 5vw;
  padding-right: 5vw;
}