.footer{
    background-color: #A3D261;
    color: #2B2822;
    width: 100%;
    padding: 2vw;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.footerMobile{
    background-color: #A3D261;
    color: #2B2822;
    width: 100%;
    padding-left: 5vw;
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;;
}

.footer a{
    color: #2B2822;
}
.footer a:hover{
    color: #2B2822;
    text-decoration: underline;
}
.footerMobile a{
    color: #2B2822;
}
.footerMobile a:hover{
    color: #2B2822;
    text-decoration: underline;
}
  .copyright {
    font-family: 'PT Sans';
    font-size: 12px !important;
    font-weight: bold;
    font-size: 85%;
  }
  .footerLogo {
    display: flex;
    text-align: left;
    margin-bottom: 10px;
  }
  
  .footerLinksMobile {
    padding-top: 10px;
  }
  
  .footerSocials {
    display: flex;
    align-items: center;
    gap: 30px;
  }



  .footerSocialsMobile {
    padding-top: 10px;
    display: flex;
    align-items: center;
    gap: 30px;
  }

