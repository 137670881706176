
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200&family=Montserrat:wght300;400;500;700&family=Roboto:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: "PosteramaRegular";   /*Can be any text*/
  src: local("PosteramaRegular"),
    url("./fonts/PosteramaRegular.ttf") format("truetype");
}

@font-face {
  font-family: "BerlinSans";   /*Can be any text*/
  src: local("BRLNSR"),
    url("./fonts/BRLNSR.TTF") format("truetype");
}

@font-face {
  font-family: "OpenSansLight";   /*Can be any text*/
  src: local("OpenSansLight"),
    url("./fonts/OpenSansLight.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSansBold";   /*Can be any text*/
  src: local("OpenSansBold"),
    url("./fonts/OpenSansBold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSansRegular";   /*Can be any text*/
  src: local("OpenSansRegular"),
    url("./fonts/OpenSansRegular.ttf") format("truetype");
}


.socialItem:hover {
  filter: invert(50%) sepia(70%) saturate(662%) hue-rotate(77deg)
    brightness(98%) contrast(100%);
}



.show>.dropdown-menu{
  display: block;
  position:absolute;
}

body { 
  background: #595959   !important;
  margin: 0px !important; 
  font-family: 'PT Sans', sans-serif;
  overflow-y: scroll; /* Show vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}




.underlined{
  text-decoration: underline;
}

.border-3 {
    border-width:3px !important;
}

.mainModal { 
  background: #171A21 !important; 
  width: 90%;
}

.astronaut{
  position: absolute;
  top: 27%;
  right: 30px !important;
  width: 30vw;
  padding-left: 0vw;
  z-index: 10;
}

.pandaDonut{
  position: absolute;
  top: 10px;
  right: 10px !important;
  width: 27vw;
  padding-left: 5vw;
  z-index: 1;
}

.pandaGround{
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 15vw;
  z-index: 2;
}
.pandaGround2{
  position: absolute;
  bottom: 0;
  left: 55vw;
  width: 12vw;
  z-index: 1;
}
.pandaBackside{
  position: absolute;
  bottom: 0;
  left: 15vw;
  width: 9vw;
  z-index: 1;
}
.pandaRocket{
  position: absolute;
  top: 6%;
  right: 100px;
  width: 20vw;
  z-index: 1;
}
.pandaNotebook{
  position: absolute;
  bottom: 0;
  left: 40vw;
  width: 13vw;
  z-index: 1;
}
.pandaMusicLeft{
  position: absolute;
  bottom: 0;
  right: 10vw;
  width: 10vw;
  z-index: 1;
}
.pandaMusicRight{
  position: absolute;
  bottom: 0;
  right: 1vw;
  width: 10vw;
  z-index: 1;
}
.pandaCatching{
  position: absolute;
  bottom: 30%;
  right: 170px;
  width: 17vw;
  transform: scaleX(-1);
  z-index: 1;
}
.bamboo{
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 350px;
  z-index: 0;
}



.image4 {
  position: relative;
  width: 100%;
  background-image: url(./img/graphics/TWIN_Chapter4_Background.png);
  background-size: cover;
  background-repeat: no-repeat;
  min-height:100vh;
  margin: 0vw;
  padding-left: 50vw;
  padding-right: 4vw;
}

.image5 {
  position: relative;
  width: 100%;
  background-image: url(./img/graphics/TWIN_Chapter5_Background.png);
  background-size: cover;
  background-repeat: no-repeat;
  
}
.image6 {
  position: relative;
  width: 100%;
  background-image: url(./img/graphics/TWIN_Chapter6_Background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center; /* Align the background image to the right */
}






.navbar{
    display: flex;
    justify-content: right;
    align-items: center;
    font-family: OpenSansRegular;
    font-size: 1.7vw;
    font-weight: bold;
    color: #006ddc;
    padding-right: 5vw;
    padding-top: 10px;
    padding-left: 50% !important;

}



.colorBlue {
  color: #006ddc !important;
}
.colorGreen {
  color: #84ff0d !important;
}
.colorOrange {
  color: #ffad12 !important;
}







