.chapter6Mobile {
  position: relative;
  width: 100%;
  font-family: BerlinSans;
  letter-spacing: 0.05rem;
  color: #ffffff;
  padding-right:5vw;
  opacity: 1;
  margin: 0 !important;
  background-color: #006ddc;
  padding-left: 5vw;
  z-index: 0; 
}

.chapter6MobileLayer{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #006ddc;
  opacity: 0.6;
  z-index: -1;
}  

.chapterMobile6H1 {
  font-family: BerlinSans;
  letter-spacing: 0.05rem;
  font-size: max(calc(3.5vw), 35px);
  padding-top: 40px;
  opacity: 1;
  width: 100vw;
  margin: 0;
  z-index: 0;
}

.chapter6MobileText {
  font-family: OpenSansLight;
  font-size: max(calc(1.8vw), 18px);
  padding-bottom: 100px;
  padding-top: 5vh;
  opacity: 1;
  margin: 0;
  z-index: 10;
}

.chapter6MobileBamboo{
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 350px;
  z-index: -2;
}

.chapter6MobilePandaGround{
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 15vw;
  z-index: 2;
}
.chapter6MobilePandaGround2{
  position: absolute;
  bottom: 0;
  left: 55vw;
  width: 12vw;
  z-index: 1;
}
.chapter6MobilePandaBackside{
  position: absolute;
  bottom: 0;
  left: 15vw;
  width: 9vw;
  z-index: 1;
}
.chapter6MobilePandaRocket{
  position: absolute;
  top: 100px;
  right: 100px;
  width: 20vw;
  z-index: 1;
}
.chapter6MobilePandaNotebook{
  position: absolute;
  bottom: 0;
  left: 40vw;
  width: 13vw;
  z-index: 1;
}
.chapter6MobilePandaMusicLeft{
  position: absolute;
  bottom: 0;
  right: 10vw;
  width: 10vw;
  z-index: 1;
}
.chapter6MobilePandaMusicRight{
  position: absolute;
  bottom: 0;
  right: 1vw;
  width: 10vw;
  z-index: 1;
}
.chapter6MobilePandaCatching{
  position: absolute;
  bottom: 30%;
  right: 170px;
  width: 17vw;
  transform: scaleX(-1);
  z-index: 10;
}