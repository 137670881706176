.chapter1MobileBackground{
    position: relative;
    width: 100%;
    background-image: url(../img/graphics/TWIN_Chapter1_Background.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.chapter2Mobile{
    position: relative;
    min-height: 40vh;
    margin: 0vw;
    background-color: #0b0b0b;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-bottom: 10vh;
    width: 100%;
  }
  .chapterMobile2H1{
    text-transform: uppercase;
    text-align: center;
    font-family: BerlinSans;
    letter-spacing: 0.2rem;
    font-size: calc(max(3.2vw,32px));
    color: #ffffff;
    padding-top: 5.75vh;
    opacity: 1;
    z-index: 1;
    margin: 0;
  }
  
  .chapter2MobileContent {
    display: flex;
    flex-wrap: wrap;
    font-size: calc(max(1.7vw,17px));
    font-family: OpenSansBold;
    letter-spacing: 0.1rem;
    font-size: calc(max(2vw,20px));
    color: #ffffff;
    padding-top: 10vh;
    opacity: 1;
    width: 100%;
    margin: 0;
  }
  
  .chapter2MobileContentHeadline{
    color: #84ff0d;
    font-size: calc(max(2.7vw,27px));
  }
  .chapter2MobileText{
    font-family: OpenSansLight;
    letter-spacing: 0.0rem;
    font-size: calc(max(2vw,20px));
    margin-top: 2vh;
  }